import React from 'react'
import Link from 'gatsby-link'
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from "gbimage-bridge"
import { MDBMask, MDBRow, MDBCol, MDBView, MDBContainer } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRss, faMessageMiddle } from '@fortawesome/pro-regular-svg-icons'

class Hero extends React.Component {
  render() {
      const sources = this.props.type === (('news' || this.props.type === 'blog') && !this.props.ctaslug) ? 
      [
        {aspectRatio: 1, src: '', srcSet: ''},
        {...this.props.image, media: `(min-width: 480px)`}, 
      ] 
      :
      this.props.image

    const bgImage = convertToBgImage(this.props.image)
    return (
      <BackgroundImage
        Tag="section"
        className={this.props.class}
        alt={this.props.alt}
        {...bgImage}
        preserveStackingContext
      >
        <MDBView>
          <MDBMask className={ this.props.gradientClass != null ? this.props.gradientClass : 'mask d-flex justify-content-center gradient' } >
            <MDBContainer className="h-100">
              <MDBRow className="align-items-center h-100">
              {this.props.type === 'platforms' ||
                this.props.type === 'root' ||
                this.props.type === 'search' ||
                this.props.type === 'sitemap' ||
                this.props.type === '404' ? (
                  <>
                    <MDBCol lg="6" className="text-lg-left text-center" >
                      <h1
                        className="font-alt font-w-700 text-white title-sm-large title-extra-large-1"
                        dangerouslySetInnerHTML={{ __html: this.props.title, }}
                      />

                      {this.props.subtitle &&
                        <h2
                          className="font-alt font-w-400 title-xs-medium title-small text-white letter-spacing-1 mt-5 mb-xl-5"
                          dangerouslySetInnerHTML={{ __html: this.props.subtitle }}
                        />
                      }
                    {this.props.ctaslug && (
                      <>
                      {this.props.ctaslug.substring(0,4) === 'http' ?
                        <a href={this.props.ctaslug} target="_blank" className="btn btn-mdb-color mr-0 mt-5 mt-lg-2">
                          <FontAwesomeIcon icon={faMessageMiddle} className="mr-2 va-m" />{' '}
                          {this.props.ctatext ? this.props.ctatext : 'Schedule a consultation'}
                        </a>
                       :
                        <Link to={this.props.ctaslug} className="btn btn-mdb-color mr-0 mt-5 mt-lg-2" >
                          <FontAwesomeIcon icon={faMessageMiddle} className="mr-2 va-m" />{' '}
                          {this.props.ctatext != '' && this.props.ctatext != null ? this.props.ctatext : 'Read'}
                        </Link>
                      }
                      </>
                    )}
                    </MDBCol>
                  </>
                ) : this.props.type === 'news' ? (
                  <MDBCol lg="12" className="mx-auto text-lg-left text-center mt-3" >
                    <h1
                      className="font-alt font-w-700 text-white title-sm-large title-extra-large-1 mb-3"
                      dangerouslySetInnerHTML={{ __html: this.props.title }}
                    />
                    {this.props.subtitle != '' && this.props.subtitle != null && (
                      <h2
                        className="text-white font-w-400 font-alt mb-4 mb-md-4 title-xs-medium title-small letter-spacing-1"
                        dangerouslySetInnerHTML={{ __html: this.props.subtitle }}
                      />
                    )}

                    {this.props.ctaslug != '' && this.props.ctaslug != null && (
                      <a href={this.props.ctaslug} target="_blank" className="btn btn-mdb-color mr-0 mt-xl-3" > 
                        <FontAwesomeIcon icon={faRss} className="mr-2 va-m" />{' '}
                        {this.props.ctatext != '' && this.props.ctatext != null ? this.props.ctatext : 'RSS Feed'}
                      </a>
                    )}
                  </MDBCol>
                ) : (
                  <div className="white-text text-center col-md-12 mt-xl-2">
                    <h1
                      className="text-white title-sm-large title-extra-large-1 mt-sm-5 mb-xl-4"
                      dangerouslySetInnerHTML={{ __html: this.props.title }}
                    />

                    {this.props.subtitle != '' && this.props.subtitle != null && (
                      <h2
                        className="text-white font-alt font-w-400 mb-4 title-xs-medium title-small letter-spacing-1"
                        dangerouslySetInnerHTML={{ __html: this.props.subtitle }}
                      />
                    )}

                    {this.props.ctaslug && (
                      <>
                      {this.props.ctaslug.substring(0,4) === 'http' ?
                        <a href={this.props.ctaslug} target="_blank" className="nav-link btn btn-mdb-color mr-0 mt-5 mt-lg-2">
                          <FontAwesomeIcon icon={faMessageMiddle} className="mr-2 va-m" />{' '}
                          {this.props.ctatext ? this.props.ctatext : 'Schedule a consultation'}
                        </a>
                       :
                        <Link to={this.props.ctaslug} className="btn btn-mdb-color mr-0 mt-5 mt-lg-2" >
                          <FontAwesomeIcon icon={faMessageMiddle} className="mr-2 va-m" />{' '}
                          {this.props.ctatext != '' && this.props.ctatext != null ? this.props.ctatext : 'Read'}
                        </Link>
                      }
                      </>
                    )}
                  </div>
                )}
              </MDBRow>
            </MDBContainer>
          </MDBMask>
        </MDBView>
      </BackgroundImage>
    )
  }
}

export default Hero