import React, { Component } from 'react'
import { Link } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol, toast, ToastContainer } from 'mdbreact'
import { Zoom } from 'react-toastify'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'

const validationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(2, 'Name must be 2 characters minimum')
    .required('First name is required'),
  lastname: Yup.string()
    .min(2, 'Name must be 2 characters minimum')
    .required('Last name is required'), 
  email: Yup.string()
   .email('Invalid email address format')
   .required('Email is required'),   
  phone: Yup.string()
   .min(2, 'Phone must be 2 characters minimum')
   .required('Phone is required'),
  company: Yup.string()
   .min(2, 'Company must be 2 characters minimum')
   .required('Company is required'),
  city: Yup.string()
   .min(2, 'City must be 2 characters minimum')
   .required('City is required'),      
  country: Yup.string()
   .min(2, 'Name must be 2 characters minimum')
   .required('Country is required'),
  zip: Yup.string()
   .min(2, 'Zip/Post code must be 2 characters minimum')
   .required('Zip/Post code is required'),
  message: Yup.string()
   .min(5, 'Message must be 5 characters at minimum')
   .required('Message is required'),   
  // subscribe: Yup.bool().oneOf([true], 'Terms and conditions need to be accepted'),   
})

const formUrl = 'https://script.google.com/macros/s/AKfycbyZjkMI3W7rzJQNP47C-qSIvXSz8F1qNjA0ZiWZmGoww2BjIidPDppDSr97bno-zkaHpQ/exec'

const messageConfirm = () => {
  return toast.info("Thank you for contacting us.  We'll be in touch shortly!", { closeButton: false, position: toast.POSITION.TOP_CENTER, transition: Zoom })
}

class formSalesInquiry extends Component {
  constructor(props, ...args) {
    super(props, ...args)
    this.state = {
      callback: 'not fired',
      value: null,
      pageUrl: null,
      load: false,
      expired: 'false',
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ 
        load: true,
        pageUrl: location.href 
      })
    })
  }

  handleChange = (value) => {
    this.setState({ value })
  }

  asyncScriptOnLoad = () => {
    this.setState({ callback: 'called!' })
  }

  render() {
    const { value, callback, load, expired } = this.state || {}

    return (
      <MDBContainer>
        <MDBRow className="justify-content-center">
          <MDBCol lg="9" md="12" className="md-0 mb-5">
            <Formik
              initialValues={{
                firstname: '',
                lastname: '',
                email: '',
                phone: '',
                company: '',
                city: '',
                state: '',
                country: '',
                zip: '',  
                message: '',
                subscribe: false,
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                /* alert('Form is validated! Submitting the form...') */
                let formData = {
                  FirstName: values.firstname,
                  LastName: values.lastname,
                  Email: values.email,
                  Phone: values.phone,
                  Company: values.company,
                  City: values.city,
                  State: values.state,
                  Country: values.country,   
                  Zip: values.zip,                                
                  Message: values.message,
                  Subscribe: values.subscribe,
                }
                try {
                  const response = axios({
                    method: 'get',
                    url: `${formUrl}?timestamp=&url=${encodeURIComponent(this.state.pageUrl)}&firstname=${encodeURIComponent(values.firstname)}&lastname=${encodeURIComponent(values.lastname)}&email=${encodeURIComponent(values.email)}&phone=${encodeURIComponent(values.phone)}&company=${encodeURIComponent(values.company)}&city=${encodeURIComponent(values.city)}&state=${encodeURIComponent(values.state)}&country=${encodeURIComponent(values.country)}&zip=${encodeURIComponent(values.zip)}&message=${encodeURIComponent(values.message)}&subscribe=${encodeURIComponent(values.subscribe)}`
                  })
                  //console.log(response)
                  this.setState({ values: '', expired: 'true' })
                  resetForm()
                  messageConfirm()
                } catch (e) {
                  console.log(`Axios request failed: ${e}`)
                }
                setSubmitting(false)
              }}
            >
              {({ touched, errors, values, isSubmitting }) => (
                <Form
                  name="contact"
                  method="post"
                  action=""
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="training-registration" />



                  <section className="form-section rounded mt-n5">
                    <MDBRow>
                        <MDBCol md="12">
                          <div className="mb-4 grey-text font-w-600 text-medium">
                            <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left mb-3 mt-3">Personal information</h2>
                            <hr />
                          </div>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow>
                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="firstname">
                            First name <span style={{ color: 'red' }}>*</span>
                          </label>
                          <Field
                            type="firstname"
                            name="firstname"
                            aria-label="firstname"
                            value={values.firstname}
                            placeholder="Your first name"
                            className={`form-control ${ touched.firstname && errors.firstname ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage component="div" name="firstname" className="invalid-feedback" />
                        </div>
                      </MDBCol>

                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                            <label htmlFor="lastname">
                              Last name <span style={{ color: 'red' }}>*</span>
                            </label>
                            <Field
                              type="lastname"
                              name="lastname"
                              aria-label="lastname"
                              value={values.lastname}
                              placeholder="Your last name"
                              className={`form-control ${ touched.lastname && errors.lastname ? 'is-invalid' : '' }`}
                            />
                            <ErrorMessage component="div" name="lastname" className="invalid-feedback" />
                          </div>
                      </MDBCol>                
                    </MDBRow>
                    
                    <MDBRow>
                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                            <label htmlFor="email">
                              Email <span style={{ color: 'red' }}>*</span>
                            </label>
                            <Field
                              type="email"
                              name="email"
                              aria-label="email"
                              value={values.email}
                              placeholder="Your email address"
                              className={`form-control ${ touched.email && errors.email ? 'is-invalid' : '' }`}
                            />
                            <ErrorMessage component="div" name="email" className="invalid-feedback" />
                          </div>
                      </MDBCol>

                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                            <label htmlFor="phone">
                              Phone <span style={{ color: 'red' }}>*</span>
                            </label>
                            <Field
                              type="phone"
                              name="phone"
                              aria-label="phone"
                              value={values.phone}
                              placeholder="Your phone number"
                              className={`form-control ${ touched.phone && errors.phone ? 'is-invalid' : '' }`}
                            />
                            <ErrorMessage component="div" name="phone" className="invalid-feedback" />
                          </div>
                      </MDBCol>                
                    </MDBRow>
                  </section>

                  <section className="form-section rounded">
                    <MDBRow>
                        <MDBCol md="12">
                          <div className="mb-4 grey-text font-w-600 text-medium">
                            <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left mb-3 mt-3">Company details</h2>
                            <hr />
                          </div>
                        </MDBCol>
                    </MDBRow>
                  
                    <MDBRow>
                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="company">
                            Company <span style={{ color: 'red' }}>*</span>
                          </label>
                          <Field
                            type="company"
                            name="company"
                            aria-label="company"
                            value={values.company}
                            placeholder="Your company name"
                            className={`form-control ${ touched.company && errors.company ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage component="div" name="company" className="invalid-feedback" />
                        </div>
                      </MDBCol>

                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="city">
                            City <span style={{ color: 'red' }}>*</span>
                          </label>
                          <Field
                            type="city"
                            name="city"
                            aria-label="city"
                            value={values.city}
                            placeholder="Company city"
                            className={`form-control ${ touched.city && errors.city ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage component="div" name="city" className="invalid-feedback" />
                        </div>
                      </MDBCol>                
                    </MDBRow>

                    <MDBRow>
                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="state">
                            State
                          </label>
                          <Field
                            type="state"
                            name="state"
                            aria-label="state"
                            value={values.state}
                            placeholder="Company state"
                            className={`form-control ${ touched.state && errors.state ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage component="div" name="state" className="invalid-feedback" />
                        </div>
                      </MDBCol>


                      <MDBCol lg="6" md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <label htmlFor="zip">
                            Zip/Post code <span style={{ color: 'red' }}>*</span>
                          </label>
                          <Field
                            type="zip"
                            name="zip"
                            aria-label="zip"
                            value={values.zip}
                            placeholder="Company zip/post code"
                            className={`form-control ${ touched.zip && errors.zip ? 'is-invalid' : '' }`}
                          />
                          <ErrorMessage component="div" name="zip" className="invalid-feedback" />
                          </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                    <MDBCol lg="6" md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="country">
                          Country <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="country"
                          name="country"
                          aria-label="country"
                          value={values.country}
                          placeholder="Company country"
                          className={`form-control ${ touched.country && errors.country ? 'is-invalid' : '' }`}
                        />
                        <ErrorMessage component="div" name="country" className="invalid-feedback" />
                      </div>
                      </MDBCol>
                    </MDBRow>                   
                 </section> 


                 <section className="form-section rounded">
                    <MDBRow>
                      <MDBCol md="12">
                        <div className="mb-4 grey-text font-w-600 text-medium">
                          <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left mb-3 mt-3">Your inquiry</h2>
                          <hr />
                        </div>
                      </MDBCol>
                    </MDBRow>

                    <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="message">
                          Message <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="textarea"
                          component="textarea"
                          name="message"
                          aria-label="message"
                          value={values.message}
                          placeholder="Please leave us a message"
                          className={`form-control ${ touched.message && errors.message ? 'is-invalid' : '' }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="message"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  </section>             

                  <hr /> 

                  <MDBRow className="mt-5">
                    <MDBCol md="12">
                      <div className="mt-3 mb-4 grey-text font-w-400 text-small">
                        To receive occasional updates about Firetide's
                        solutions, services, products and news, please tick the box
                        below. You can unsubscribe at any time.
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="form-group form-check mt-3 mb-4 grey-text font-w-400 text-small">
                        <Field
                          type="checkbox"
                          id="subscribe"
                          name="subscribe"
                          aria-label="subscribe"
                          className={`form-check-input ${
                            touched.subscribe && errors.subscribe
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        <label htmlFor="subscribe">
                          Yes, I would like to receive communications from
                          Firetide.
                        </label>
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-400 text-small">
                        For details on how your data is stored and used, please
                        visit our{' '}
                        <Link to="/privacy-statement/" className="effect">
                          Privacy Statement
                        </Link>
                        .
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12" className="mb-4">
                      <div style={{ paddingTop: '1rem' }}>
                        <button
                          type="submit"
                          className="mt-4 btn btn-medium btn-unicom"
                          style={{ display: 'block' }}
                          disabled={isSubmitting ? true : false}
                        >
                          {isSubmitting ? 'Please wait...' : 'Submit'}
                        </button>
                        <ToastContainer
                          transition={Zoom}
                          hideProgressBar={true}
                          newestOnTop={true}
                          autoClose={5000}
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                </Form>
              )}
            </Formik>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }
}

export default formSalesInquiry